// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'
import { css } from 'glamor'
import { graphql } from 'gatsby'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
// import { Link } from 'gatsby'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import StandardPage from '../components/StandardPage'

import Infrastructure from '../components/commons/Infrastructure'

import seoHelper from '../methods/seoHelper'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const pageData = {
  pageTitle: 'Infrastructure',
  nakedPageSlug: 'infrastructure',
  pageAbstract:
    'The Cambridge Innovative School has continued to build and extend its commitment to enhancing its facilities and towards the all-round development of its students. Ample opportunities are made available to students to engage in academic and other activities. Our goal has been to maximize the use of space in an environmentally responsible and sustainable fashion..',
}

const seoData = seoHelper(pageData)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const query = graphql`
  query {
    art2: file(relativePath: { eq: "infrastructure/art2.jpg" }) {
      ...defaultImage
    }
    art3: file(relativePath: { eq: "infrastructure/art3.jpg" }) {
      ...defaultImage
    }
    art4: file(relativePath: { eq: "infrastructure/art4.jpg" }) {
      ...defaultImage
    }
    art5: file(relativePath: { eq: "infrastructure/art5.jpg" }) {
      ...defaultImage
    }
    biolab2: file(relativePath: { eq: "infrastructure/biolab2.jpeg" }) {
      ...defaultImage
    }
    chemlab: file(relativePath: { eq: "infrastructure/chemlab.jpg" }) {
      ...defaultImage
    }
    homelab: file(relativePath: { eq: "infrastructure/homelab.jpg" }) {
      ...defaultImage
    }
    ibrary3: file(relativePath: { eq: "infrastructure/ibrary3.jpg" }) {
      ...defaultImage
    }
    itlab1: file(relativePath: { eq: "infrastructure/itlab1.jpeg" }) {
      ...defaultImage
    }
    itlab2: file(relativePath: { eq: "infrastructure/itlab2.jpeg" }) {
      ...defaultImage
    }
    library1: file(relativePath: { eq: "infrastructure/library1.jpg" }) {
      ...defaultImage
    }
    library2: file(relativePath: { eq: "infrastructure/library2.jpg" }) {
      ...defaultImage
    }
    library3: file(relativePath: { eq: "infrastructure/library3.jpg" }) {
      ...defaultImage
    }
    library4: file(relativePath: { eq: "infrastructure/library4.jpg" }) {
      ...defaultImage
    }
    mathlab1: file(relativePath: { eq: "infrastructure/mathlab1.jpeg" }) {
      ...defaultImage
    }
    musicdance1: file(relativePath: { eq: "infrastructure/musicdance1.jpg" }) {
      ...defaultImage
    }
    musicdance2: file(relativePath: { eq: "infrastructure/musicdance2.jpg" }) {
      ...defaultImage
    }
    musicdance3: file(relativePath: { eq: "infrastructure/musicdance3.jpg" }) {
      ...defaultImage
    }
    musicdance4: file(relativePath: { eq: "infrastructure/musicdance4.jpg" }) {
      ...defaultImage
    }
    musicdance5: file(relativePath: { eq: "infrastructure/musicdance5.jpg" }) {
      ...defaultImage
    }
    musicdance6: file(relativePath: { eq: "infrastructure/musicdance6.jpg" }) {
      ...defaultImage
    }
    physicslab: file(relativePath: { eq: "infrastructure/physicslab.jpg" }) {
      ...defaultImage
    }
    sports11: file(relativePath: { eq: "infrastructure/sports11.jpg" }) {
      ...defaultImage
    }
    sports2: file(relativePath: { eq: "infrastructure/sports13.jpeg" }) {
      ...defaultImage
    }
    sports3: file(relativePath: { eq: "infrastructure/sports12.jpg" }) {
      ...defaultImage
    }
    sports4: file(relativePath: { eq: "infrastructure/sports14.jpg" }) {
      ...defaultImage
    }
    swim1: file(relativePath: { eq: "infrastructure/swim1.jpg" }) {
      ...defaultImage
    }
    swim2: file(relativePath: { eq: "infrastructure/swim2.jpg" }) {
      ...defaultImage
    }
    specialneeds: file(relativePath: { eq: "infrastructure/specialneeds.jpg" }) {
      ...defaultImage
    }
    socialscience1: file(relativePath: { eq: "infrastructure/socialscience1.jpeg" }) {
      ...defaultImage
    }
    socialscience2: file(relativePath: { eq: "infrastructure/socialscience2.jpeg" }) {
      ...defaultImage
    }
    socialscience3: file(relativePath: { eq: "infrastructure/socialscience3.jpeg" }) {
      ...defaultImage
    }
    playroom1: file(relativePath: { eq: "infrastructure/playroom1.jpeg" }) {
      ...defaultImage
    }
    playroom2: file(relativePath: { eq: "infrastructure/playroom2.jpeg" }) {
      ...defaultImage
    }
    playroom3: file(relativePath: { eq: "infrastructure/playroom3.jpeg" }) {
      ...defaultImage
    }
    playroom4: file(relativePath: { eq: "infrastructure/playroom4.jpeg" }) {
      ...defaultImage
    }
    classroom1: file(relativePath: { eq: "infrastructure/classroom1.jpeg" }) {
      ...defaultImage
    }
    classroom2: file(relativePath: { eq: "infrastructure/classroom2.jpeg" }) {
      ...defaultImage
    }
    classroom3: file(relativePath: { eq: "infrastructure/classroom3.jpeg" }) {
      ...defaultImage
    }
    classroom4: file(relativePath: { eq: "infrastructure/classroom4.jpeg" }) {
      ...defaultImage
    }

   




    iconArtAndCrafts: file(
      relativePath: { eq: "infrastructure/icons/artAndCrafts.png" }
    ) {
      ...defaultImage
    }
    iconLibrary: file(
      relativePath: { eq: "infrastructure/icons/library.png" }
    ) {
      ...defaultImage
    }
    iconScienceLab: file(
      relativePath: { eq: "infrastructure/icons/scienceLab.png" }
    ) {
      ...defaultImage
    }
    iconMusicStudies: file(
      relativePath: { eq: "infrastructure/icons/musicStudies.png" }
    ) {
      ...defaultImage
    }
    iconSocialScienceLab: file(
      relativePath: { eq: "infrastructure/icons/socialScienceLab.png" }
    ) {
      ...defaultImage
    }
    iconSports: file(relativePath: { eq: "infrastructure/icons/sports.png" }) {
      ...defaultImage
    }
    iconPlayroom: file(
      relativePath: { eq: "infrastructure/icons/playroom.png" }
    ) {
      ...defaultImage
    }
    iconSwimmingPool: file(
      relativePath: { eq: "infrastructure/icons/swimmingPool.png" }
    ) {
      ...defaultImage
    }
    iconSecurity: file(
      relativePath: { eq: "infrastructure/icons/security.png" }
    ) {
      ...defaultImage
    }
    iconSpecialNeeds: file(
      relativePath: { eq: "infrastructure/icons/specialNeeds.png" }
    ) {
      ...defaultImage
    }
    iconIctLab: file(relativePath: { eq: "infrastructure/icons/ictLab.png" }) {
      ...defaultImage
    }
    iconClassrooms: file(
      relativePath: { eq: "infrastructure/icons/classrooms.png" }
    ) {
      ...defaultImage
    }
  }
`

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Styles
// ----------------------------------------------------------------------------
const pageStyles = css({
  '& strong': {
    fontWeight: 700,
    color: '#004195',
  },

  '& h1, h2, h3, h4, h5, h6': {
    color: '#004195',
  },

  '& h1': {
    textTransform: 'uppercase',
    fontStyle: 'italic',
    borderTop: '4px solid #004394',
  },

  '& h2': {
    borderTop: '2.6px solid #004394',
  },

  '& h3': {
    borderTop: '1.5px solid #004394',
  },
})
const pageStyle = pageStyles.toString()

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = props => (
  <StandardPage className={pageStyle} seoData={seoData}>
    <h1 className="mask-h3">Infrastructure at CIS</h1>
    <p>
      The Cambridge Innovative School has continued to build and
      extend its commitment to enhancing its facilities and towards the
      all-round development of its students. Ample opportunities are made
      available to students to engage in academic and other activities. Our goal
      has been to maximize the use of space in an environmentally responsible
      and sustainable fashion.
    </p>
    <Infrastructure data={props.data} />
  </StandardPage>
)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Page
