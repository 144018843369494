// ------------------------------------------------------------------------------
// ---------------------------------------------------------------------- Imports
// ------------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
import PropTypes from 'prop-types'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Lodash
import isUndefined from 'lodash/isUndefined'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import Division from '@bodhi-project/components/lib/Division'
import '@bodhi-project/antrd/lib/se-concern-lwes-type/3.10.0/row/style/css'
import '@bodhi-project/antrd/lib/se-concern-lwes-type/3.10.0/col/style/css'

import Img from 'gatsby-image'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import NewImages from './NewImages'
import NewInfobox from './NewInfoBox'

import Copy from '../Copy'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Styles
// ----------------------------------------------------------------------------
// show = [
//   'artAndCrafts',
//   'library',
//   'scienceLab',
//   'musicStudies',
//   'socialScienceLab',
//   'sports',
//   'playroom',
//   'swimmingPool',
//   'security',
//   'specialNeeds',
//   'ictLab',
//   'classrooms',
// ],

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Infrastructure */
const Infrastructure = ({
  schoolName = 'Cambridge Innovative School',
  shortSchoolName = 'CIS',
  ...props
}) => {
  const { data } = props
  const display = [
    {
      index: 0,
      iconKey: 'iconArtAndCrafts',
      text: 'Art and Crafts',
      info: () => (
        <Copy>
          <h4>Art and Crafts</h4>
          <p>
            All Cambridge International Schools boast of well-equipped Art and
            Craft rooms. Children enjoy hobby classes of soft toys and doll
            making, knitting, needle work, weaving, applique work, bead work,
            book binding, candle making, pottery, sculpture with wood and clay,
            various kinds of painting, embroidery, fashion designing, theatre,
            recycling of paper, cooking, baking, etc.
          </p>
          <p>
            "<i>Art is an international language understood by all</i>
            ".
          </p>
          <p>
            Art Room is a divine place, where you can imagine shades of all
            dreams in abundance, where you can express your feelings with the
            help of representational colours. The walls of this functional room
            display the glowing dreams shining in the eyes of our children and
            give them an opportunity to represent their creativity and novelty,
            which makes the on lookers spellbound. Our art room is also fully
            equipped with colours, brushes, easel stands and all required
            materials.
          </p>
          <div className="margin-p">
            <NewImages data={data} lookup="art" columns={{ min: 2, max: 2 }} />
          </div>
        </Copy>
      ),
    },
    {
      index: 1,
      iconKey: 'iconLibrary',
      text: 'Library',
      info: () => (
        <Copy>
          <h4>Library</h4>
          <p>
            "
            <i>
              Information is the heartbeat of meaningful learning in schools
            </i>
            ".
          </p>
          <p>
            At Cambridge Innovative School Library, we strive to create a
            learning environment that empowers the students to connect with,
            engage with and utilize this information in meaningful and
            purposeful ways. We have developed strong library and information
            services which are essential to a learning program that values the
            cognitive, cultural, social, affective, technological and spiritual
            development of students. Our Library also has internet based
            facility for searching the books. Students are welcomed during the
            time allotted to each class and are also encouraged to come during
            the break time. Our school library is a learning resource centre as
            it houses information resources - the expansive reading material.
          </p>
          <div className="margin-p">
            <NewImages
              data={data}
              lookup="library"
              columns={{ min: 3, max: 3 }}
            />
          </div>
        </Copy>
      ),
    },
    {
      index: 2,
      iconKey: 'iconScienceLab',
      text: 'Labs',
      info: () => (
        <Copy>
          <h4>Our Labs</h4>
          <p>
            Cambridge Innovative School provides a first hand experience in all practical
            subjects. All the labs in the school are well equipped with latest
            apparatus, techniques and instruments.
          </p>
          <Division>
            <div>
              {!isUndefined(data.biolab2) && (
                <Fragment>
                  <h5>Biology Lab</h5>
                  <Img fluid={data.biolab2.childImageSharp.fluid} />
                </Fragment>
              )}
            </div>
            <div>
              {!isUndefined(data.chemlab) && (
                <Fragment>
                  <h5>Chemistry Lab</h5>
                  <Img fluid={data.chemlab.childImageSharp.fluid} />
                </Fragment>
              )}
            </div>
          </Division>
          <Division>
            <div>
              {!isUndefined(data.physicslab) && (
                <Fragment>
                  <h5>Physics Lab</h5>
                  <Img fluid={data.physicslab.childImageSharp.fluid} />
                </Fragment>
              )}
            </div>
            <div>
              {!isUndefined(data.mathlab1) && (
                <Fragment>
                  <h5>Maths Lab</h5>
                  <Img fluid={data.mathlab1.childImageSharp.fluid} />
                </Fragment>
              )}
            </div>
          </Division>
          <Division>
            <div>
              {!isUndefined(data.itlab1) && (
                <Fragment>
                  <h5>ICT Lab</h5>
                  <Img fluid={data.itlab1.childImageSharp.fluid} />
                </Fragment>
              )}
            </div>
            <div>
              {!isUndefined(data.homelab) && (
                <Fragment>
                  <h5>Home Science Lab</h5>
                  <Img fluid={data.homelab.childImageSharp.fluid} />
                </Fragment>
              )}
            </div>
          </Division>
        </Copy>
      ),
    },
    {
      index: 3,
      iconKey: 'iconMusicStudies',
      text: 'Music and Dance',
      info: () => (
        <Copy>
          <h4>Music and Dance</h4>
          <p>
            Indian classical & folk dance, western music and dance, instrumental
            music and singing are offered to students to develop their skills
            and musical appreciation. Each child takes part in atleast one
            activity at the annual cultural event to develop stage confidence.
            In an effort to make our students 'complete Artists' with discipline
            and dedication, we have well-equipped three separate rooms for
            Dance, Music Vocal and music Instrumental.
          </p>
          <div className="margin-p">
            <NewImages
              data={data}
              lookup="musicdance"
              columns={{ min: 3, max: 3 }}
            />
          </div>
        </Copy>
      ),
    },
    {
      index: 4,
      iconKey: 'iconSocialScienceLab',
      text: 'Social Science Lab',
      info: () => (
        <Copy>
          <h4>Social Science Lab</h4>
          <p>
            Social Science lab facilitates its students to face the research
            world where the atmosphere is very tough. It is well equipped with
            the apparatuses and material required for the subject enrichment. It
            helps in creating and maintaining a much needed congenial atmosphere
            for effective learning. Permanent display of important maps, globes,
            pictures, models etc. and constant reference to them make teaching
            of Social Science effective, lively and interesting. Some of the
            concepts where imagination is difficult are taught with the help of
            models i.e. landforms, drainage pattern, volcanic eruption,
            structure of dams etc. It also provides a platform for students to
            share their creativity and innovation.
          </p>
          <div className="mask-p">
            <NewImages
              data={data}
              lookup="socialscience"
              columns={{ min: 2, max: 2 }}
            />
          </div>
        </Copy>
      ),
    },
    {
      index: 5,
      iconKey: 'iconSports',
      text: 'Sports',
      info: () => (
        <Copy>
          <h4>Sports</h4>
          <p>
            The development of mind and body go hand-in-hand. In short, physical
            education plays a vital role in creating well-rounded, confident
            individuals. Sports are an integral part of our education system.
          </p>
          <p>
            To make them good at their respective sport, every Cambridge Innovative School
             has expert coaches and trainers, who impart
            invaluable tips to children to hone their skills. All our schools
            have a huge sports ground, an athletic track, tennis courts,
            basketball and badminton courts that reflect our seriousness towards
            sports. We have world class facilities for hockey, cricket,
            football, volleyball, table tennis, badminton, throw ball, and
            basketball.
          </p>
          <div className="mask-p">
            <NewImages
              data={data}
              lookup="sports"
              columns={{ min: 2, max: 2 }}
            />
          </div>
        </Copy>
      ),
    },
    {
      index: 6,
      iconKey: 'iconPlayroom',
      text: 'Play Room',
      info: () => (
        <Copy>
          <h4>Play Room</h4>
          <p>
            Play is fundamental to every kind of learning and it even physically
            shapes the brain. That’s why we have made free play a part of our
            primary curriculum. Free play, unstructured play happens when our
            children don’t follow any rules. They build forts, pretend to be a
            superhero or do finger painting. Our children are enthusiastic and
            are always looking forward for ‘Free Play’ as this is the time when
            they express themselves in their own accord and we get to know them
            in a better way.
          </p>
          <div className="mask-p">
            <NewImages data={data} lookup="playroom" columns={{ min: 2, max: 2 }} />
          </div>
        </Copy>
      ),
    },
    {
      index: 7,
      iconKey: 'iconSwimmingPool',
      text: 'Swimming Pool',
      info: () => (
        <Copy>
          <h4>Swimming Pool</h4>
          <p>
            {schoolName}&nbsp;has a well equipped world class swimming pool.
          </p>
          <div className="mask-p">
            <NewImages data={data} lookup="swim" columns={{ min: 2, max: 2 }} />
          </div>
        </Copy>
      ),
    },
    {
      index: 8,
      iconKey: 'iconSecurity',
      text: 'Security',
      info: () => (
        <Copy>
          <h4>Security Infrastructure</h4>
          <p>
            For the safety and security of the students, the school gates are
            manned 24 hours. Furthermore two female and five male guards are
            deployed round the clock. Self going students are not allowed to
            leave the school without valid gate pass and even the parents are
            issued with Parent Identity Card which they have to show to the
            school authority before taking their wards. A student boards/gets
            off the school bus only at specific stop mentioned by the parents.
            This ensures the safety of the students. Visitors are not allowed to
            enter in the school premises without valid permission from the
            school authorities. As mandated by Indian law, the school has
            relevant CCTV cameras.
          </p>
        </Copy>
      ),
    },
    {
      index: 9,
      iconKey: 'iconSpecialNeeds',
      text: 'Accessibility',
      info: () => (
        <Copy>
          <h4>Accessibility</h4>
          <p>
            Our Special Needs Program aims to improve through inclusion,
            education and advocacy the quality of life of differently abled
            children. We are committed to meeting the diverse needs of children
            and creating opportunities for students with special educational
            needs in the mainstream. Our program’s vision is to reach out to all
            learners and enable them to receive meaningful and equitable access
            to the curriculum. It is our goal that each student receive the
            necessary resources, guidance, accommodations, and differentiation
            to attain their highest level of personal success. We value
            collaboration and shared responsibility with students, parents and
            community partners to create a culture of inclusivity, compassion
            and respect for individual differences.
          </p>
          <Img fluid={data.specialneeds.childImageSharp.fluid} />
        </Copy>
      ),
    },
    {
      index: 10,
      iconKey: 'iconIctLab',
      text: 'ICT Lab',
      info: () => (
        <Copy>
          <h4>ICT Lab</h4>
          <p>
            Fully networked and equipped with latest computers. Modern IT
            applications with interactive tools support the IBM curriculum being
            followed in the school. The school has two fully equipped state of
            the art computer labs for its students. Drill and practice,
            individual and peer project-work, assessments are few of the
            practices followed here at Cambridge.
          </p>
          <div className="mask-p">
            <NewImages data={data} lookup="itlab" columns={{ min: 2, max: 2 }} />
          </div>
        </Copy>
      ),
    },
    {
      index: 11,
      iconKey: 'iconClassrooms',
      text: 'Classrooms',
      info: () => (
        <Copy>
          <h4>Modern Classrooms</h4>
          <p>
            Our Classroom provide a productive environment in which students can
            develop the skills they will require in the workplace and where
            teachers are facilitators of their learning. The focus of these
            classrooms is on students experiencing the environment they will
            enter as modern day workers and developing their higher order
            thinking skills, effective communication skills, collaboration
            skills, making them adapt with using technology and all other skills
            in their day to day life.
          </p>
          <div className="mask-p">
            <NewImages data={data} lookup="classroom" columns={{ min: 2, max: 2 }} />
          </div>
        </Copy>
      ),
    },
  ]

  return <NewInfobox data={data} display={display} defaultIndex={0} />
}

Infrastructure.propTypes = {}

/* eslint-disable react/forbid-prop-types */
Infrastructure.propTypes = {
  show: PropTypes.array,
  exclude: PropTypes.array,
  columns: PropTypes.array,
  divisionProps: PropTypes.array,
  activeStyle: PropTypes.array,
  inactiveStyle: PropTypes.array,
  schoolName: PropTypes.string,
  shortSchoolName: PropTypes.string,
  artPhotos: PropTypes.array,
  libraryPhotos: PropTypes.array,
  labs: PropTypes.object,
  musicDancePhotos: PropTypes.array,
  sportsPhotos: PropTypes.array,
  swimmingPoolPhotos: PropTypes.array,
}

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default Infrastructure
